@import "src/styles/variables";

.App {
  height: 100%;
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  // word-break: break-word;
  color: rgb(52, 58, 64);
}

.site-layout {
  height: 100%;
}

.site-content {
  height: 100%;
}

.moveable-control {
  background: rgb(35, 108, 250) !important;
}

.moveable-line {
  background: rgb(35, 108, 250) !important;
}

.moveable-rotation-control {
  border-color: rgb(35, 108, 250) !important;
}

.moveable-control.moveable-origin {
  display: none !important;
}

.swiper-pagination-horizontal {
  top: 3px !important;
  max-width: calc(100% - 24px);
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.swiper-pagination-progressbar-fill {
  border-radius: 6px !important;
}

.ant-drawer-content-wrapper {
  max-width: 100%;
}

.mantine-Modal-content {
  background: rgba(48, 48, 48, 1);
}
.mantine-Modal-header {
  background: rgba(48, 48, 48, 1);
}

.react-tiny-popover-container {
  background: white;
  color: black;
  border-radius: 4px;
  z-index: 3;

  box-shadow: 0 0 18px 0px rgba(0, 0, 0, 0.1);
  ul {
    cursor: pointer;
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
    padding-inline-start: 0;

    li {
      padding: 8px 12px;
      list-style: none;
    }
  }
}

:root {
  --toastify-font-family: "Mulish", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap');
@import "react-toastify/dist/ReactToastify.css";

// global
@import "./styles/variables";
@import "./styles/global";

// components
@import "features/features";
